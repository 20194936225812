import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import tooltipStyle from 'assets/jss/material-dashboard-react/tooltipStyle';
import SettingPanel from 'components/Setting/OverviewCardSettingPanel';
import Tooltip from '@material-ui/core/Tooltip';
import { grey } from '@material-ui/core/colors';
import scientificToDecimal from 'scientific-to-decimal';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { dispatch as CustomDispatch } from 'redux/actions/index';
import IconButton from '@material-ui/core/IconButton';
import $scope from 'consts/scope';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { deepCompare, truncate, countDecimals, getValueColor } from 'assets/js/common';
import { selectConfigPairs, selectPairData, selectStrategiesConfig, selectPairOrders } from 'redux/selectors';
import GetUserTemplates from 'components/Gunbot/GetUserTemplates';

import parseInput from 'components/Gunbot/ParseInput';

const defaultCoinImage = require(`assets/coins/coin.svg`);

const styles = theme => ({
  ...dashboardStyle,
  settingPanel: {
    height: '400px',
    overflow: 'auto',
  },
  overviewHeader: {
    padding: '14px 0 0 0 !important',
  },
  cardHeaderContent: {
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'nowrap',
    },
  },
  chartWrapper: {
    [theme.breakpoints.up('lg')]: {
      flex: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  descriptionWrapper: {
    minWidth: 200,
    fontSize: 22,
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 3,
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  cardHeaderContentAlone: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  chartWrapperAlone: {
    [theme.breakpoints.up('sm')]: {
      flex: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      // flexGrow: 0,
      // maxWidth: "100%",
      // flexBasis: "100%",
    },
  },
  descriptionWrapperAlone: {
    minWidth: 200,
    fontSize: 22,
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 3,
    },
    [theme.breakpoints.down('sm')]: {
      // flexGrow: 0,
      // maxWidth: "100%",
      // flexBasis: "100%",
    },
  },
  root: {
    flexGrow: 1,
  },
});

const defaultStratAll = { ...$scope.defaultStrat, ...$scope.defaultStratBR, ...$scope.defaultStratMM };

const useStyle = makeStyles(styles);

const StyledTooltip = withStyles(() => ({
  tooltip: tooltipStyle.tooltip,
}))(Tooltip);

function OverViewCard(props) {
  const {
    pair,
    exchange,
    alone = false,
    sideBar = false,
    showSettings = false,
    showStats = false,
    isMobile = false,
  } = props;

  const classes = useStyle();
  const dispatch = useDispatch();
  const key = `${exchange}/${pair}`;
  const pairData = useSelector(selectPairData(key) || {}); 
  const pairs = useSelector(selectConfigPairs);
  const strategies = useSelector(selectStrategiesConfig);
  const exchangeDetails = useSelector(state => state.settings.config.exchanges);
  const orders = useSelector(selectPairOrders(key));
  const [extrasExpanded, setExtrasExpanded] = useState(true);
  const [userTemplates, setUserTemplates] = useState({});

  const ftxMarket = _.isNil(exchangeDetails.ftx)
    ? 'spot'
    : !_.isNil(exchangeDetails.ftx.market)
    ? exchangeDetails.ftx.market
    : 'spot';
  const okexMarket = _.isNil(exchangeDetails.okex5)
    ? 'spot'
    : !_.isNil(exchangeDetails.okex5.market)
    ? exchangeDetails.okex5.market
    : 'spot';
  const okgbMarket = _.isNil(exchangeDetails.okgunbot)
    ? 'spot'
    : !_.isNil(exchangeDetails.okgunbot.market)
    ? exchangeDetails.okgunbot.market
    : 'spot';
  const bybitMarket = _.isNil(exchangeDetails.bybit)
    ? 'spot'
    : !_.isNil(exchangeDetails.bybit.market)
    ? exchangeDetails.bybit.market.includes('spot') ? 'spot' : exchangeDetails.bybit.market
    : 'spot';
  const exchangeMarket = exchangeDetails[exchange]?.market || 'spot';

  const [data, setData] = useState({
    abp: 'loading',
    roe: 'loading',
    ask: 'loading',
    bid: 'loading',
    profitMM: 'loading',
    bag: 'loading',
    bagCurrency: '',
    state: '',
    side: 'loading',
    leverage: 'loading',
    maintenanceMargin: 'loading',
    unrealizedROEpcnt: 'loading',
    availableMargin: 'loading',
    liquidationPrice: 'loading',
    marginRatio: 'loading',
    marginUsage: 'loading',
    freeCollateral: 'loading',
  });
  const [eest, setEest] = useState('Estimated holding');
  const [bep, setBep] = useState('Break even');

  const [settingData, setSettingData] = useState([]);
  const [sortedStrategies, setSortedStrategies] = useState([]);
  const [newOverride, setNewOverride] = useState({
    label: '',
    value: '',
  });

  const handleExpand = function () {
    setExtrasExpanded(!extrasExpanded);
  };

  // fetch user configs
  useEffect(() => {
    if (Object.keys(userTemplates).length < 1) {
      const fetchTemplates = async function () {
        let result = await GetUserTemplates();
        if (Object.keys(result).length < 1) {
          result = {
            placeholder_: true,
          };
          setUserTemplates(result);
        } else {
          let finalResult = {};
          Object.keys(result).forEach(element => {
            finalResult[element.toLowerCase()] = result[element];
          });
          setUserTemplates(finalResult);
        }
      };
      fetchTemplates();
    }
  }, []);

  /*
  useEffect(() => {
    if (_.isNil(memory)) {
      // do nothing
    } else if (_.isNil(memory[exchange + '/' + pair])) {
      // do nothing
    } else {
      setPairData(memory[exchange + '/' + pair] || {});
    }
  }, [exchange, pair, memory]);
  */

  useEffect(() => {
    if (_.isNil(pairData)) {
      return
    }
    let abp = 'loading';
    let roe = 'loading';
    let aboveMvts = false;

    if (
      !_.isNil(pairData.Bid) &&
      !_.isNil(pairData.ABP) &&
      !_.isNil(pairData.quoteBalance) &&
      !_.isNil(pairData.whatstrat.MIN_VOLUME_TO_SELL) &&
      !_.isNil(pairData.reversal)
    ) {
      aboveMvts =
        (pairData.quoteBalance - parseFloat(pairData.whatstrat.KEEP_QUOTE)) * pairData.Bid >
          parseFloat(pairData.whatstrat.MIN_VOLUME_TO_SELL) || pairData.reversal;
    }

    if (!exchangeMarket.includes('spot')) {
      roe = !_.isNil(pairData?.precalculatedFuturesRoe) ? pairData?.precalculatedFuturesRoe : 'loading';
      abp = !_.isNil(pairData?.precalculatedAvgEntryPrice) ? pairData?.precalculatedAvgEntryPrice : 'loading';
      if (exchangeMarket === 'delivery') {
        setEest('Pos. size (coin|usd)'); 
      }
      else {
        setEest('Position size');
      }
    } else {
      roe = !_.isNil(pairData?.precalculatedSpotRoe) ? pairData?.precalculatedSpotRoe : 'loading';
      abp = !_.isNil(pairData?.precalculatedSpotAbp) ? pairData?.precalculatedSpotAbp : 'loading';
      setEest('Bag value');
    }

    let side = 'n/a';
    let leverage = 'n/a';
    let maintenanceMargin = 'n/a';
    let unrealizedROEpcnt = 'n/a';
    let availableMargin = 'n/a';
    let liquidationPrice = 'n/a';
    let marginRatio = 'n/a';
    let marginUsage = 'n/a';
    let freeCollateral = 'n/a';
    let unrealizedPrecision =
      ['okex5', 'okgunbot'].indexOf(exchange) > -1 &&
      (okexMarket === 'swap' || okexMarket === 'futures', okgbMarket === 'swap' || okgbMarket === 'futures') &&
      pair.split('-')[0] !== 'USDT'
        ? 6
        : 2;
    if (exchangeMarket === 'delivery') {
      unrealizedPrecision = 4
    }

    const isFuturesMarket = (exchange, market) => {
      const futuresExchanges = ['okex5', 'okgunbot', 'bitget', 'binanceFutures', 'dydx', 'futures_gunthy', 'krakenFutures', 'bitmex', 'bitmex_testnet', 'kumex', 'bybit', 'ftx'];
      return (futuresExchanges.includes(exchange) && market !== 'spot') || (exchange === 'ftx' && market === 'futures');
    };
    
    const safeToFixed = (value, precision = 2) => (_.isNil(value) ? 'n/a' : parseFloat(value).toFixed(precision));
    const safeToPrecision = (value, precision = 2) => (_.isNil(value) ? 'loading' : parseFloat(value).toPrecision(precision));
    const safeParseFloat = (value) => (_.isNil(value) ? 'loading' : scientificToDecimal(parseFloat(value)));

    if (isFuturesMarket(exchange, exchangeMarket)) {
      side = _.isNil(pairData['currentQty']) || pairData['currentQty'] === 0 ? 'No position' : pairData['currentQty'] < 0 ? 'Short' : 'Long';
      leverage = safeToFixed(Math.abs(pairData['leverage']), 0) + 'x';
      maintenanceMargin = safeToFixed(pairData['maintenanceMargin']);
      unrealizedROEpcnt = safeToFixed(pairData['unrealizedROEpcnt'], unrealizedPrecision);
      availableMargin = safeToFixed(pairData['availableForOrders']) || safeToFixed(pairData['availableMargin']) || safeToFixed(pairData['availableFunds']);
      liquidationPrice = safeToFixed(pairData['liquidationPrice'], 4);
    
      if (exchangeMarket === 'delivery') {
        unrealizedROEpcnt += ` | ${safeToFixed(pairData['unrealizedROEpcnt'] * pairData.Bid, 2)}`;
      }
    
      if (!_.isNil(pairData.whatstrat) && !_.isNil(pairData.whatstrat.PAIR)) {
        if (['binanceFutures', 'dydx', 'futures_gunthy'].includes(exchange) && pairData.whatstrat.PAIR.split('-')[0] !== 'USDT') {
          availableMargin = safeToFixed(pairData['availableForOrders'], 4);
        }
    
        if (exchange === 'ftx') {
          availableMargin = safeToFixed(pairData['baseBalance'], 4);
        }
      }
    
      marginRatio = `${safeToFixed(parseFloat(pairData['maintenanceMargin']) / parseFloat(pairData['availableMargin']) * 100, 2)}%`;
    }
    
    const ask = safeParseFloat(pairData['Ask']);
    const bid = safeParseFloat(pairData['Bid']);
    
    let profitMM = truncate(_.last(pairData['globaltotalholdingUSDTV']), countDecimals(ask));
    profitMM = profitMM || 0.00000001;
    
    let bag = 'loading';
    let bagCurrency = '';
    
    if (!exchangeMarket.includes('spot') && !_.isNil(pairData['currentQty'])) {
      bag = pairData.currentQty;
      if (exchangeMarket === 'delivery') {
        bag += ` | ${safeToPrecision(bag * pairData.Bid, 2)}`;
      }
    } else if (!_.isNil(pairData['reversal'])) {
      bag = pairData.reversal === true
        ? safeToFixed(pairData.ourBaginBase)
        : aboveMvts === true
          ? safeToFixed((pairData.quoteBaseValue || parseFloat(pairData.quoteBalance) * parseFloat(pairData.Bid)), pair.split('-')[0].includes('USD') ? 2 : 6)
          : pairData.quoteBaseValue > 0 ? safeToFixed(pairData.quoteBaseValue, pair.split('-')[0].includes('USD') ? 2 : 6) : 'n/a';
    
      bagCurrency = pairData.whatstrat && pairData.whatstrat.PAIR ? pairData.whatstrat.PAIR.split('-')[0] : 'n/a';
    }
    
    const state = pairData['state'];
    
    marginUsage = `${safeToFixed((1 - parseFloat(pairData?.maintenanceMargin) / parseFloat(pairData?.walletBalance)) * 100, 2)}%`;
    freeCollateral = parseFloat(pairData?.maintenanceMargin);
    
    if (localStorage.getItem('privacyMode') == 'true') {
      profitMM = '●●●●●';
      bag = '●●●●●';
      abp = '●●●●●';
      maintenanceMargin = '●●●●●';
      roe = '●●●●●';
      unrealizedROEpcnt = '●●●●●';
      availableMargin = '●●●●●';
      liquidationPrice = '●●●●●';
      unrealizedROEpcnt = '●●●●●';
      marginRatio = '●●●●●';
      marginUsage = '●●●●●';
      freeCollateral = '●●●●●';
    }

    setData({
      abp,
      ask,
      roe,
      bid,
      profitMM,
      bag,
      bagCurrency,
      state,
      side,
      leverage,
      maintenanceMargin,
      unrealizedROEpcnt,
      availableMargin,
      liquidationPrice,
      marginRatio,
      marginUsage,
      freeCollateral,
    });
  }, [pairData, exchange]);

  useEffect(() => {
    let bep = 'Break even';

    if (!_.isNil(pairData)) {
      if (!_.isNil(pairData.whatstrat)) {
        if (
          pairData?.whatstrat?.unit_cost === true ||
          (pairData.whatstrat.DYNAMIC_EXIT_LOGIC === true && pairData.strategySetABP !== pairData.ABP)
        ) {
          bep = 'Unit cost';
        }
      }
    }

    if (!exchangeMarket.includes('spot')) {
      bep = 'Entry price';
    }

    setBep(bep);
  }, [exchange, orders, pairData]);

  useEffect(() => {
    const newSortedStrategies = Object.keys(strategies).sort();
    setSortedStrategies(_oldSortedStrategies => {
      return deepCompare(_oldSortedStrategies, newSortedStrategies) ? _oldSortedStrategies : newSortedStrategies;
    });
  }, [strategies]);

  useEffect(() => {
    if (!exchange || !pair) return;
    const exchanges = pairs[exchange] || {};
    const pairConfig = exchanges[pair] || {};
    const overrideData = pairConfig.override || {};
    const isUserStrat = !_.isNil(exchanges?.[pair])
      ? Object.keys(userTemplates).includes(exchanges[pair].strategy)
      : false;
    const addOverride = () => {
      const { value, label } = newOverride;
      dispatch(CustomDispatch('putConfig', value, label, 'override', pair, exchange, 'pairs'));
      setNewOverride({ value: '', label: '' });
    };
    const removeOverride = key => {
      dispatch(CustomDispatch('putConfig', undefined, key, 'override', pair, exchange, 'pairs'));
    };

    let userSettingsList = {};
    let userSettingsListTooltips = {};
    let userSettingsDefaultValues = {};
    let userSettingsListInverted = {};

    const allTemplates = {...userTemplates, ...$scope.easyStratEditor}
    const stratRef = exchanges?.[pair]?.strategy || 'stepgrid'
    Object.keys(allTemplates?.[stratRef] || {}).forEach(item => {
      if (item === 'info') {
        return;
      }
      
      Object.keys(allTemplates[stratRef][item].parameters).forEach(setting => {
        userSettingsList[setting] = allTemplates[stratRef][item].parameters[setting].label;
        userSettingsListTooltips[setting] =
        allTemplates[stratRef][item].parameters[setting]?.tooltip || '';
        userSettingsDefaultValues[setting] =
        allTemplates[stratRef][item].parameters[setting].defaultValue;
      });
    });
    userSettingsListInverted = _.invert(userSettingsList);

    let readableList = $scope.overrides.map(item => {
      return $scope.title[item];
    });

    readableList = [...readableList, ...Object.values(userSettingsList)];

    const renderedOverrideData = [
      {
        type: 'form',
        label: 'Add',
        name: 'overrideSetting',
        children: [
          {
            name: 'label',
            label: 'Parameter',
            value: userSettingsList[newOverride.label] || $scope.title[newOverride.label] || newOverride.label,
            tooltip: 'Select a strategy parameter to override for this pair',
            options: readableList.sort(),
            labelWidth: 'auto',
            valueWidth: 'auto',
            placeholder: 'BTC-ETC',
            onChanged: newValue => {
              setNewOverride({
                label: isUserStrat ? userSettingsListInverted[newValue] : _.invert($scope.title)[newValue],
                value: isUserStrat
                  ? userSettingsDefaultValues[userSettingsListInverted[newValue]]
                  : !_.isNil(defaultStratAll[_.invert($scope.title)[newValue]])
                  ? parseInput(defaultStratAll[_.invert($scope.title)[newValue]])
                  : '0',
              });
            },
            onInputChanged: newValue => {
              setNewOverride({
                label: isUserStrat ? userSettingsListInverted[newValue] : _.invert($scope.title)[newValue],
                value: isUserStrat
                  ? userSettingsDefaultValues[userSettingsListInverted[newValue]]
                  : !_.isNil(defaultStratAll[_.invert($scope.title)[newValue]])
                  ? parseInput(defaultStratAll[_.invert($scope.title)[newValue]])
                  : '0',
              });
            },
          },
          {
            name: 'value',
            label: 'Value',
            value: newOverride.value,
            tooltip: userSettingsListTooltips[newOverride.label] || $scope.tooltips[newOverride.label] || '',
            placeholder: 'Override Value',
            onChanged: newValue => {
              setNewOverride({
                ...newOverride,
                value: parseInput(newValue),
              });
            },
          },
        ],
        onClick: addOverride,
      },
      ...Object.keys(overrideData).map(label => {
        const value = overrideData[label];
        return {
          name: label,
          label: userSettingsListInverted[label] || userSettingsList[label] || $scope.title[label] || label,
          tooltip: userSettingsListTooltips[label] || $scope.tooltips[label] || '',
          value: parseInput(value),
          labelWidth: '195px',
          valueWidth: 'auto',
          removable: true,
          onRemove: () => {
            removeOverride(label);
          },
          onChanged: newValue => {
            dispatch(CustomDispatch('putConfig', parseInput(newValue), label, 'override', pair, exchange, 'pairs'));
          },
        };
      }),
    ];

    if (Object.keys(exchanges).length > 0 && Object.keys(exchanges).indexOf(pair) > -1) {
      setSettingData([
        {
          name: 'enabled',
          value: exchanges[pair].enabled,
          label: 'Enabled',
          width: '100%',
          onChanged: newValue => {
            dispatch(CustomDispatch('putConfig', newValue, 'enabled', pair, exchange, 'pairs'));
          },
        },
        {
          type: 'component',
          component: (
            <div style={{ marginTop: 20 }}>
              <SettingPanel
                className={'override-setting-panel'}
                data={renderedOverrideData}
                isSub={true}
                title={'Override Settings'}
                description={'Add or modify override settings for the selected pair.'}
              />
            </div>
          ),
        },
      ]);
    }
  }, [pair, exchange, pairs, sortedStrategies, newOverride, userTemplates, dispatch]);

  const {
    abp,
    ask,
    roe,
    bid,
    bag,
    bagCurrency,
    side,
    leverage,
    unrealizedROEpcnt,
    availableMargin,
    liquidationPrice,
    marginRatio,
    marginUsage,
    freeCollateral,
  } = data;

  let showRoe = 'ROE';

  if (!pair) {
    return null;
  }

  if (sideBar && showStats) {
    return (
      <div className={'overview-pair-card'} style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '80vh' }}>
        {exchange === 'dydx' ? (
          <>
            <div className={classes.root} style={{ overflow: 'hidden' }}>
              <Grid container spacing={2} style={{}}>
                <Grid item xs style={{ textAlign: 'center' }}>
                  <p color={grey[200]} className={classes.cardCategory} style={{ fontSize: '22px' }}>
                    {pair.replace('-', ' - ')}
                  </p>
                  <h6 className={classes.cardTitleSidebar} style={{ fontSize: 'medium', fontWeight: '600' }}>
                    <span style={{ color: '#56ca00' }}> {bid}</span> / <span style={{ color: '#f13c1d' }}>{ask} </span>
                  </h6>
                </Grid>
              </Grid>

              <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                <Grid item xs>
                  <StyledTooltip
                    placement="top"
                    title={`Return on Equity (ROE) measures the profitability of your investment relative to the amount of money you've put into it. It shows your net profit after considering any commissions or fees you've paid.`}
                  >
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>ROE</small>
                      <br />
                      <span
                        style={{
                          color: getValueColor(roe),
                          fontSize: 'larger',
                          fontWeight: '600',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        {_.isFinite(parseFloat(roe)) ? roe : 'n/a'}
                      </span>
                    </h5>
                  </StyledTooltip>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Entry price</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{abp}</span>
                  </h5>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Position size</small>
                    <br />
                    <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                      {' '}
                      {bag}{' '}
                    </span>
                  </h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Side</small>
                    <br />
                    <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                      {side}
                    </span>
                  </h5>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Leverage</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{leverage}</span>
                  </h5>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Margin usage</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{marginUsage}</span>
                  </h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{exchangeMarket !== 'delivery' ? 'Unrealized PNL' : 'Unrealized (coin|usd)'}</small>
                    <br />
                    <span
                      style={{
                        color: getValueColor(roe),
                        fontSize: 'larger',
                        fontWeight: '600',
                        textAlign: 'center',
                      }}
                    >
                      {unrealizedROEpcnt}
                    </span>
                  </h5>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Liquidation price</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{liquidationPrice}</span>
                  </h5>
                </Grid>
                <Grid item xs>
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Free collateral</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{freeCollateral}</span>
                  </h5>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div className={classes.root} style={{ overflow: 'hidden' }}>
              <Grid container spacing={2} style={{}}>
                <Grid item xs style={{ textAlign: 'center' }}>
                  <p color={grey[200]} className={classes.cardCategory} style={{ fontSize: '22px' }}>
                    {pair.replace('-', ' - ')}
                  </p>
                  <h6 className={classes.cardTitleSidebar} style={{ fontSize: 'medium', fontWeight: '600' }}>
                    <span style={{ color: '#56ca00' }}> {bid}</span> / <span style={{ color: '#f13c1d' }}>{ask} </span>
                  </h6>
                </Grid>
              </Grid>

              <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                <Grid item xs>
                  <StyledTooltip
                    placement="top"
                    title={`Return on Equity (ROE) measures the profitability of your investment relative to the amount of money you've put into it. It shows your net profit after considering any commissions or fees you've paid.`}
                  >
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{showRoe}</small>
                      <br />
                      <span
                        style={{
                          color: getValueColor(roe),
                          fontSize: 'larger',
                          fontWeight: '600',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        {roe}
                      </span>
                    </h5>
                  </StyledTooltip>
                </Grid>
                <Grid item xs>
                <StyledTooltip
                    placement="top"
                    title={`The cost of a single unit of the asset.`}
                  >
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{bep || ''}</small>
                    <br />
                    <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{abp}</span>
                  </h5>
                  </StyledTooltip>
                </Grid>
                <Grid item xs>
                <StyledTooltip
                    placement="top"
                    title={`This represents the total amount of trading capital invested in this asset at this time.`}
                  >
                  <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                    <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>
                      {eest} {eest === 'Bag value' ? bagCurrency : null}
                    </small>
                    <br />
                    <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                      {' '}
                      {bag}{' '}
                    </span>
                  </h5>
                  </StyledTooltip>
                </Grid>
              </Grid>
              {exchange === 'bitmex' || exchange === 'bitmex_testnet' || exchange === 'krakenFutures' ? (
                <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Side</small>
                      <br />
                      <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                        {side}
                      </span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Leverage</small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{leverage}</span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>
                        Liquidation price
                      </small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                        {liquidationPrice}
                      </span>
                    </h5>
                  </Grid>
                </Grid>
              ) : null}
              {exchange === 'binanceFutures' ||
              exchange === 'futures_gunthy' ||
              (exchange === 'bybit' && !bybitMarket.includes('spot')) ||
              (exchange === 'ftx' && ftxMarket === 'futures') ||
              (exchange === 'okgunbot' && okgbMarket != 'spot') ||
              (exchange === 'okex5' && okexMarket != 'spot') ? (
                <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Side</small>
                      <br />
                      <span style={{ color: grey[200], fontSize: 'larger', fontWeight: '600', textAlign: 'center' }}>
                        {side}
                      </span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Leverage</small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{leverage}</span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Margin ratio</small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{marginRatio}</span>
                    </h5>
                  </Grid>
                </Grid>
              ) : null}
              {exchange === 'binanceFutures' ||
              exchange === 'futures_gunthy' ||
              (exchange === 'bybit' && !bybitMarket.includes('spot') ) ||
              (exchange === 'ftx' && ftxMarket === 'futures') ||
              (exchange === 'okgunbot' && okgbMarket != 'spot') ||
              (exchange === 'bitget' && exchangeMarket != 'spot') ||
              (exchange === 'okex5' && okexMarket != 'spot') ? (
                <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>Unrealized PNL</small>
                      <br />
                      <span
                        style={{
                          color: getValueColor(roe),
                          fontSize: 'larger',
                          fontWeight: '600',
                          textAlign: 'center',
                        }}
                      >
                        {unrealizedROEpcnt}
                      </span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>
                        Liquidation price
                      </small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                        {liquidationPrice}
                      </span>
                    </h5>
                  </Grid>
                  <Grid item xs>
                    <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                      <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>
                        Avail. for orders
                      </small>
                      <br />
                      <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>{availableMargin}</span>
                    </h5>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </>
        )}
        {!_.isNil(pairData) && !_.isNil(pairData.sidebarExtras) && pairData.sidebarExtras.length > 0 && extrasExpanded && (
          <>
            <Grid container spacing={1} style={{ marginBottom: '8px', marginTop: '8px' }}>
              {pairData.sidebarExtras.map((item, key) => {
                return (
                  <Grid item xs={4} lg={4} xl={4} key={key}>
                    <StyledTooltip placement="top" title={item?.tooltip || ''}>
                      <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'center' }}>
                        <small style={{ fontSize: 'small', lineHeight: 1.5, marginRight: '0rem' }}>{item.label}</small>
                        <br />
                        <span
                          style={{
                            color: _.isNil(item.valueColor) ? grey[200] : item.valueColor,
                            fontSize: 'larger',
                            fontWeight: '600',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {item.value}
                        </span>
                      </h5>
                    </StyledTooltip>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        {!_.isNil(pairData) && !_.isNil(pairData.sidebarExtras) && pairData.sidebarExtras.length > 0 && !isMobile && (
          <>
            <IconButton component="span" onClick={handleExpand} color="default" size="small">
              {extrasExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </>
        )}
      </div>
    );
  }

  if (sideBar && showSettings) {
    return (
      <div className={'overview-pair-card'}>
        <SettingPanel data={settingData} />
      </div>
    );
  }

  return null;
  /*
    <Card style={{ width: '100%' }}>
      <CardHeader color="warning" stats icon className={classes.overviewHeader}>
        <PairIcon base={baseImage} quote={quoteImage} />
        <Grid container className={classes['cardHeaderContent' + (alone ? 'Alone' : '')]}>
          <Grid item className={classes['chartWrapper' + (alone ? 'Alone' : '')]}>
            <OverViewChart exchange={exchange} pair={pair} />
          </Grid>
          <Grid item className={classes['descriptionWrapper' + (alone ? 'Alone' : '')]}>
            <p className={classes.cardCategory} style={{ color: getValueColor(profitMM) }}>
              {bag} {bagCurrency}
            </p>
            <h6 className={classes.cardTitle}>
              <span style={{ color: 'gray' }}>{eest}</span>
            </h6>
            <p className={classes.cardCategory} color={grey[200]}>
              {pair.replace('-', ' - ')}
            </p>
            <h6 className={classes.cardTitle}>
              <span style={{ color: '#f13c1d', marginRight: '5px' }}>{ask} </span> /{' '}
              <span style={{ color: '#56ca00', marginLeft: '5px' }}> {bid}</span>
            </h6>
            <h5 className={classes.cardTitle}>
              <small>{bep || ''}</small> <span style={{ color: getValueColor(abp) }}>{abp}</span>
            </h5>
            <StyledTooltip
              placement="top"
              title={`Profit or loss when selling all funds at current price \n\nA negative value does not mean the bot has realized losses, it means the current trade has a way to go before it can completely sell in profit`}
            >
              <h5 className={classes.cardTitleSidebar} style={{ textAlign: 'left' }}>
                <small style={{ fontSize: 'small', lineHeight: 1.5 }}>{showRoe}</small>
                <br />
                <span style={{ color: getValueColor(roe), fontSize: 'larger', fontWeight: '600', textAlign: 'left' }}>
                  {' '}
                  {roe} %
                </span>
              </h5>
            </StyledTooltip>
          </Grid>
        </Grid>
      </CardHeader>
      <CardFooter stats>
        <ButtonGroup variant="text" aria-label="Basic example">
          <Button
            style={{ color: displaySetting ? '#f13c1d' : whiteColor }}
            onClick={() => {
              setDisplaySetting(!displaySetting);
            }}
          >
            Settings
          </Button>
          {alone ? null : (
            <Button
              style={{ color: whiteColor }}
              onClick={() => {
                gotoDetailView(exchange, pair, '/');
              }}
            >
              Chart
            </Button>
          )}
        </ButtonGroup>
        <h4 className={classes.cardTitle}>{state}</h4>
      </CardFooter>
      {displaySetting && (
        <div className={classes.settingPanel + ' overview-pair-card'}>
          <SettingPanel data={settingData} />
        </div>
      )}
    </Card>
    */
}

export default React.memo(OverViewCard);

OverViewCard.propTypes = {
  pair: PropTypes.string,
  exchange: PropTypes.string,
  alone: PropTypes.bool,
  sideBar: PropTypes.bool,
};


const customFilter = (pairData, key) => {
  const filteredData = {};

  for (const prop in pairData) {
    if (
      prop !== 'customChartTargets' &&
      prop !== 'customChartShapes' &&
      prop !== 'notifications' &&
      prop !== 'candlesclose' &&
      prop !== 'low' &&
      prop !== 'high' &&
      prop !== 'volume' &&
      prop !== 'orders' &&
      prop !== 'orderbook'
    ) {
      filteredData[prop] = pairData[prop];
    }
  }

  return filteredData;
};
